// @noflow
import { useLazyQuery } from '@apollo/client'
import { useCombobox } from 'downshift'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import debounce from '@/utils/debounce'
import * as Sentry from '@/utils/sentry'

import withApollo from '@/components/apollo/withApollo'

import { HOME_BREED_SELECTOR_QUERY } from '../../queries/homeBreedSelectorQuery'

import type {
  HomeBreedSelectorQuery_breeds as Breed,
  HomeBreedSelectorQuery,
  HomeBreedSelectorQueryVariables
} from '../../queries/__generated__/HomeBreedSelectorQuery'

import { BreedSelectorVariant } from '../../BreedSelector'
import ExpandableIconButton from '../ExpandableIconButton/ExpandableIconButton'

type Props = {
  loadingPlaceholder: string
  placeholder: string
  initialValue?: Breed
  customRef?: React.RefObject<HTMLInputElement>
  embeddedCTA?: boolean
  onClickCTA?: () => void
  variant: BreedSelectorVariant
  size?: number
  events: {
    onBreedSelection: (breed: Breed) => void
    onListOpen: () => void
    onFocus: () => void
    onInputEmpty: () => void
  }
}

const BreedAutocomplete = ({
  loadingPlaceholder,
  placeholder,
  events,
  initialValue,
  customRef,
  onClickCTA,
  embeddedCTA = false,
  variant = BreedSelectorVariant.Generic,
  size
}: Props): JSX.Element | null => {
  const { onListOpen, onBreedSelection, onInputEmpty } = events

  const namespace = 'paid_traffic'
  const [breeds, setBreeds] = useState<Breed[]>([])
  const [toggleIconButton, setToggleIconButton] = useState(false)

  const { t } = useTranslation(namespace)
  const [findBreeds, { loading, data, error }] = useLazyQuery<
    HomeBreedSelectorQuery,
    HomeBreedSelectorQueryVariables
  >(HOME_BREED_SELECTOR_QUERY)

  const debouncedFindBreeds = useMemo(
    () =>
      debounce((inputValue) => {
        findBreeds(inputValue)
      }, 500),
    [findBreeds]
  )

  const mixedBreed: Breed = useMemo(() => {
    return {
      __typename: 'Breed',
      id: '260',
      key: 'mixed_breed',
      name: t('breeds.mixed_breed.name', { ns: 'models' }),
      popular: false
    }
  }, [t])

  const otherBreed: Breed = useMemo(() => {
    return {
      __typename: 'Breed',
      id: '260',
      key: 'other',
      name: t('breeds.other.name', { ns: 'models' }),
      popular: false
    }
  }, [t])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    closeMenu,
    highlightedIndex,
    inputValue
  } = useCombobox({
    initialSelectedItem: initialValue,
    items: breeds,
    itemToString: (breed) => (breed ? breed.name : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onBreedSelection(selectedItem)
      }
    },
    onInputValueChange: ({ inputValue, type }) => {
      if (!inputValue) {
        setToggleIconButton(false)
        closeMenu()
        onInputEmpty()
        return
      }
      setToggleIconButton(true)

      // If the user clicks on an item, don't make a request
      if (type !== useCombobox.stateChangeTypes.ItemClick) {
        debouncedFindBreeds({ variables: { name: inputValue } })
      }

      // If we can't match a breed, ensure the user always sees a
      // 'Mixed breed' and 'Other' option
      if (data?.breeds && data.breeds.length > 0) {
        setBreeds([...data.breeds])
      } else {
        setBreeds([mixedBreed, otherBreed])
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { type: actionAndChangesType, changes } = actionAndChanges
      switch (actionAndChangesType) {
        case useCombobox.stateChangeTypes.InputBlur:
          setToggleIconButton(false)
          return {
            ...changes,
            selectedItem: null
          }
        default:
          return changes
      }
    }
  })

  const onInputFocus = useCallback(() => {
    events.onFocus && events.onFocus()
    if (!inputValue) {
      debouncedFindBreeds({
        variables: { popular: variant !== BreedSelectorVariant.Homepage }
      })
    }
    setToggleIconButton(true)
  }, [debouncedFindBreeds, events, variant, inputValue])

  const showResults = isOpen && !loading && breeds.length > 0

  React.useEffect(() => {
    if (!loading && data) {
      if (data.breeds.length > 0) {
        setBreeds(data.breeds)
      } else {
        setBreeds([mixedBreed, otherBreed])
      }
    }
  }, [data, loading, mixedBreed, otherBreed])

  React.useEffect(() => {
    if (showResults) {
      onListOpen()
    }
  }, [showResults, onListOpen])

  if (error) {
    Sentry.captureException(`Error in BREED_SELECTOR_QUERY`, {
      extra: { error: error }
    })
    return null
  }

  return (
    <div className="breed-autocomplete__container">
      <div
        className={`breed-autocomplete__breed-selector-wrapper ${
          showResults ? 'expanded' : ''
        } ${
          variant === BreedSelectorVariant.Homepage
            ? 'breed-autocomplete__breed-selector-wrapper--homepage'
            : ''
        }`}
      >
        <input
          className="breed-autocomplete__input-field text-regular-28"
          {...getInputProps({
            placeholder: loading ? loadingPlaceholder : placeholder,
            onFocus: onInputFocus,
            ref: customRef,
            size: size
          })}
        />
        {onClickCTA &&
          embeddedCTA &&
          variant !== BreedSelectorVariant.Homepage && (
            <div className="breed-autocomplete__build-box-button-wrapper">
              <ExpandableIconButton
                toggleIconButton={toggleIconButton}
                text={'breed_autocomplete.feedback.cta_build_your_box_v2'}
                namespace={namespace}
                onClick={onClickCTA}
              />
            </div>
          )}
      </div>
      <div
        className={`breed-autocomplete__results-list-wrapper ${
          !showResults ? 'hidden' : ''
        } ${
          variant === BreedSelectorVariant.Homepage
            ? 'breed-autocomplete__results-list-wrapper--homepage'
            : ''
        }`}
      >
        <ul
          className={`breed-autocomplete__results-list-wrapper__results-list ${
            !showResults ? 'hidden' : ''
          }`}
          aria-label="Breed list"
          {...getMenuProps()}
        >
          {showResults &&
            breeds.map((breed, index) => (
              <li
                className={`${
                  highlightedIndex === index
                    ? 'breed-autocomplete__highlighted'
                    : ''
                }`}
                key={breed.key}
                {...getItemProps({
                  item: breed,
                  index
                })}
              >
                {breed.name}
              </li>
            ))}
        </ul>
      </div>
      {!showResults &&
        variant === BreedSelectorVariant.Homepage &&
        onClickCTA && (
          <button
            className="btn primary full-width"
            type="button"
            onClick={onClickCTA}
          >
            {t('hero_section.button_html')}
          </button>
        )}
    </div>
  )
}

export default withApollo(BreedAutocomplete)
