// @noflow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import times from '@/utils/times'

import Paw from 'assets/images/icons/paws/paw-yellow-alt.svg'

import type { HomeBreedSelectorQuery_breeds as Breed } from '../../queries/__generated__/HomeBreedSelectorQuery'

import { BreedSelectorVariant } from '../../BreedSelector'
import BreedAutocomplete from '../BreedAutocomplete/BreedAutocomplete'
import BreedCard, {
  NonPopularBreed,
  PopularBreed
} from '../BreedCard/BreedCard'

type Props = {
  selectedBreed: PopularBreed | NonPopularBreed | null
  isPreWizardGuest: boolean
  variant: BreedSelectorVariant
  namespace: string
  onClickCTA: () => void
  events: {
    onBreedSelection: (breed: Breed) => void
    onListOpen: () => void
    onFocus: () => void
    onInputEmpty: () => void
  }
}

const BreedQuestionnaire = ({
  onClickCTA,
  events,
  namespace,
  variant = BreedSelectorVariant.Generic,
  selectedBreed,
  isPreWizardGuest
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  return (
    <div className="breed-selector--homepage">
      <div className="breed-selector--homepage__paw-container">
        {times(4).map((i) => (
          <img
            className={`breed-selector--homepage__paw-container__paw breed-selector--homepage__paw-container__paw--${
              i + 1
            }`}
            key={i}
            src={Paw}
            alt={t(`breed_hero_section.paw_alt`)}
          />
        ))}
      </div>
      <p className="text-regular-20 breed-selector--homepage__subtitle">
        {t(`breed_hero_section.text_homepage`)}
      </p>
      <div className="breed-selector--homepage__autocomplete-wrapper">
        <BreedAutocomplete
          variant={variant}
          loadingPlaceholder={t(`breed_hero_section.field_placeholder_loading`)}
          placeholder={t('breed_hero_section.field_placeholder')}
          embeddedCTA
          onClickCTA={onClickCTA}
          events={{
            onBreedSelection: events.onBreedSelection,
            onListOpen: events.onListOpen,
            onFocus: events.onFocus,
            onInputEmpty: () => null
          }}
        />
      </div>
      {selectedBreed !== null && (
        <div className="breed-selector__breed-card-section breed-selector__breed-card-section--homepage">
          <div className="breed-selector__breed-card-wrapper breed-selector__breed-card-wrapper--homepage">
            <BreedCard
              selectedBreed={selectedBreed}
              isPreWizardGuest={isPreWizardGuest}
              namespace={namespace}
              variant={variant}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BreedQuestionnaire
