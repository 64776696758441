// @noflow

/* eslint-disable i18next/no-literal-string */
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import useBoolean from '@/hooks/useBoolean'

import Text, {
  AllowedColours,
  Props as TextProps
} from '@/components/shared/elements/Text/Text'

import STYLES from './Button.module.sass'

import { ButtonSkeleton, Props as ButtonSkeletonProps } from './ButtonSkeleton'

type Variant =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'boost'
  | 'facebook'
  | 'whatsApp'
  | 'linkWhiteBG'
  | 'ghostWhiteText'
  | 'link'

type Size = 'large' | 'regular' | 'slim'
type Icon = {
  component: JSX.Element | undefined
  position: 'left' | 'right'
}
type DisplayText = 'always' | 'never' | 'mobileOnly' | 'desktopOnly'

type Event =
  | React.MouseEvent<HTMLButtonElement>
  | React.KeyboardEvent<HTMLButtonElement>

type Skeleton = {
  isLoading: boolean
} & ButtonSkeletonProps

type ButtonProps = {
  id?: string
  dataTestId?: string
  typography?: TextProps
  variant?: Variant
  size?: Size
  disabled?: boolean
  displayText?: DisplayText
  icon?: Icon | null | undefined
  skeleton?: Skeleton
  onClick?: (e: Event) => void
  disabledOnClick?: (e: Event) => void
  fullWidth?: boolean
  smallPadding?: boolean
}

type Props = ButtonProps &
  AnalyticsProps & { ref?: React.RefObject<HTMLElement> }

const LARGE_BUTTON_SIZE = 60
const REGULAR_BUTTON_SIZE = 40
const SLIM_BUTTON_SIZE = 30

/**
 * Button component
 *
 * Use this component to render a button.
 *
 * This component has two states of analytics:
 * Analytics enabled (defaults to `true`)
 * - `identifier` is required - identifier of the button using the
 *  `app_place.component_identifier` format
 * - `trackDisabledPresses` is optional - boolean that appends `.disabled` to the `identifier`
 * - `screenIdentifier` is optional - identifier of the screen where the button is located
 *
 * Analytics disabled (defaults to `false`)
 * - `disableAnalytics` is required to be `true` - disables analytics
 * - `identifier`, `trackDisabledPresses` and `screenIdentifier` are not valid in this case
 *
 * @example
  ```
  import { Button } from 'components/shared/elements/Button/Button'

  <Button
    identifier="example_screen.update_action"
    trackDisabledPresses
    variant="primary"
    size="regular"
    typography={{
      text: 'translation.path.text',
      namespace: 'example-namespace',
    }}
    onClick={handeOnClick}
    disabled={isDisabled}
    disabledOnClick={handleDisabledOnClick}
  />
  ```
 *
 * @param {TextProps} [typography] - text of the button
 * @param {'primary' | 'secondary' | 'ghost'} [variant='primary'] - variant of the button
 * @param {'large' | 'regular' | 'slim'} [size='regular'] - size of the button
 * @param {boolean} [disabled=false] - is disabled flag
 * @param {'always' | 'never' | 'mobileOnly' | 'desktopOnly'} [displayText='always'] - display text flag
 * @param {Icon} [icon=null] - icon of the button
 * @param {Skeleton} [skeleton] - skeleton
 * @param {boolean} [fulllWidth=false] - fulllWidth flag
 * @param {Function} onClick - on click event
 * @param {Function} [disabledOnClick] - on click event when disabled
 * @param {boolean} [disableAnalytics=false] - disables analytics
 * @param {string} identifier - identifier of the button using the `app_place.component_identifier` format
 * @param {boolean} [trackDisabledPresses=false] - boolean that appends `.disabled` to the `identifier`
 * @param {string} [screenIdentifier=null] - identifier of the screen where the button is located
 * @param {string} [smallPadding=false] - Deprecated: smaller padding flag
 * @category Components
 * @subcategory Atoms
 * @returns {JSX.Element} - button component
 */
const Button = ({
  id,
  dataTestId,
  typography: typographyProps,
  variant = 'primary',
  size = 'regular',
  disabled = false,
  displayText = 'always',
  icon = null,
  skeleton,
  fullWidth = false,
  onClick,
  disabledOnClick,
  disableAnalytics = false,
  identifier,
  trackDisabledPresses = false,
  screenIdentifier = null,
  smallPadding = false
}: Props): JSX.Element => {
  const typography = {
    translate: true,
    ...(typographyProps as TextProps)
  }
  const { t } = useTranslation(typography?.namespace)

  const {
    value: pressed,
    setTrue: setPressed,
    setFalse: setUnpressed
  } = useBoolean(false)
  const animatedStyle = useSpring({
    transform: pressed ? 'scale(0.95)' : 'scale(1)',
    config: { duration: 100 }
  })

  const variantToTextColor = ((): AllowedColours => {
    switch (variant) {
      case 'primary': {
        return 'brandWhite'
      }
      case 'secondary':
      case 'ghost': {
        return disabled ? 'brandRed300' : 'brandRed400'
      }
      case 'boost': {
        return 'boostDark'
      }
      default: {
        return 'brandWhite'
      }
    }
  })()

  const sizeToButtonHeight = ((): number => {
    switch (size) {
      case 'large': {
        return LARGE_BUTTON_SIZE
      }
      case 'regular': {
        return REGULAR_BUTTON_SIZE
      }
      case 'slim': {
        return SLIM_BUTTON_SIZE
      }
      default: {
        return REGULAR_BUTTON_SIZE
      }
    }
  })()

  const iconPositionToClass = ((): string => {
    if (!icon) {
      return ''
    }

    const { position } = icon

    // Formats the position string by capitalizing the first letter.
    const formattedPosition =
      position.charAt(0).toUpperCase() + position.slice(1)

    return STYLES[`icon${formattedPosition}` as 'iconLeft' | 'iconRight']
  })()

  // Handlers
  const handleTrackEvent = useCallback(
    (componentIdentifier: string): void => {
      trackEvent('Component Clicked', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
    },
    [screenIdentifier]
  )

  const handleOnClick = useCallback(
    (event: Event): void => {
      if (!pressed) {
        if (disabled) {
          disabledOnClick && disabledOnClick(event)

          /**
           * If the button is disabled, we still can track the event.
           * Analytics has to be enabled (defaults to `true`) and the `trackDisabledPresses` has
           * to be provided (defaults to `null`).
           */
          if (!disableAnalytics && trackDisabledPresses) {
            handleTrackEvent(`${identifier}.disabled`)
          }
        } else if (onClick) {
          onClick(event)

          // If the button and analytics are enabled, we track the event.
          if (!disableAnalytics && identifier) {
            handleTrackEvent(identifier)
          }
        }
      }
    },
    [
      pressed,
      disabled,
      disabledOnClick,
      onClick,
      disableAnalytics,
      identifier,
      trackDisabledPresses,
      handleTrackEvent
    ]
  )

  // Animation handlers
  const handleOnPressed = useCallback(() => {
    if (!disabled || disabledOnClick) {
      setPressed()
    }
  }, [setPressed, disabled, disabledOnClick])

  const handleOnUnpressed = useCallback(() => {
    if (!disabled || disabledOnClick) {
      setUnpressed()
    }
  }, [setUnpressed, disabled, disabledOnClick])

  // Keyboard handlers
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter' && !pressed) {
        handleOnPressed()
        handleOnClick(event)
      }
    },
    [handleOnPressed, pressed, handleOnClick]
  )

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        handleOnUnpressed()
      }
    },
    [handleOnUnpressed]
  )

  if (skeleton?.isLoading) {
    return <ButtonSkeleton height={sizeToButtonHeight} {...skeleton} />
  }

  return (
    <animated.button
      id={id}
      className={`${STYLES.button} ${STYLES[variant]} ${
        STYLES[size]
      } ${iconPositionToClass} ${disabled ? STYLES.disabled : ''} ${
        fullWidth ? STYLES.fullWidth : ''
      } ${smallPadding ? STYLES.smallPadding : ''}`}
      type="button"
      aria-label={
        typography?.translate
          ? t(typography?.text)
          : (typography?.text as string)
      }
      aria-disabled={disabled}
      style={animatedStyle}
      onClick={handleOnClick}
      onMouseDown={handleOnPressed}
      onMouseUp={handleOnUnpressed}
      onMouseLeave={handleOnUnpressed}
      onTouchStart={handleOnPressed}
      onTouchEnd={handleOnUnpressed}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      data-testid={dataTestId}
    >
      {icon && (
        <div
          className={`${iconPositionToClass} ${
            displayText === 'never' ? STYLES.iconOnly : ''
          }`}
          aria-label={
            typography?.translate
              ? `${t(typography?.text)} icon`
              : `${typography?.text} icon`
          }
        >
          {icon.component}
        </div>
      )}

      {displayText !== 'never' && typography && (
        <div className={`${STYLES.buttonText} ${STYLES[displayText]}`}>
          <Text
            {...typography}
            dataTestId={`${dataTestId}-text`}
            text={typography.text}
            variant={size === 'slim' ? 'textMono16' : 'textMono18'}
            colour={variantToTextColor}
            margin={false}
            shouldScale={false}
            element="span"
          />
        </div>
      )}
    </animated.button>
  )
}

export type { Props, Event }

export { Button }
