// eslint-disable-next-line @typescript-eslint/no-explicit-any
function uniqBy<T>(array: T[], key: (item: T) => any): T[] {
  const seen = new Set()
  return array.filter((item) => {
    const keyValue = key(item)
    if (seen.has(keyValue)) {
      return false
    } else {
      seen.add(keyValue)
      return true
    }
  })
}

export default uniqBy
