// @noflow
import EstimatedDailyPlanPrices from '@/static_content/EstimatedDailyPlanPrices'
import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import uniqBy from '@/utils/uniqBy'

import Select from '@/components/shared/elements/Select/Select'
import type { Option } from '@/components/shared/elements/Select/Select'

import { dogDetailsState } from './DogDetailsForm'

const namespace = 'homepage'
const copyContext = 'rough_cost_calculator'
const baseClass = 'rough-cost-calculator__cost-calculator'

const WeightSelect = (): JSX.Element => {
  const { t } = useTranslation(namespace)

  const dogDetails = useReactiveVar(dogDetailsState)

  const weightOptions = uniqBy(
    EstimatedDailyPlanPrices,
    (estimate) => estimate.size
  )

  const formattedWeightOptions = weightOptions.map((option, index): Option => {
    return {
      id: index.toString(),
      value: option.size,
      label: t(`${copyContext}.weight.${option.size}`)
    }
  })

  const formattedWeightOption = formattedWeightOptions.find(
    (option) => option.value === dogDetails.weight
  )

  const handleWeightChange = useCallback(
    (option: Option) => {
      dogDetailsState({ ...dogDetails, weight: option.value })
    },
    [dogDetails]
  )

  return (
    <div className={`${baseClass}__dropdown-wrapper`}>
      <Select
        onChange={handleWeightChange}
        label={t(`${copyContext}.weight_label`)}
        options={formattedWeightOptions}
        initialValue={formattedWeightOption}
      />
    </div>
  )
}

export default WeightSelect
