import { gql } from '@apollo/client'

const SAVE_LANGUAGE_MUTATION = gql`
  mutation SaveLanguageUpdate($language: Language!) {
    languageUpdate(language: $language) {
      __typename
      ... on User {
        id
      }
      ... on Guest {
        id
      }
      ... on PreWizardGuest {
        id
      }
    }
  }
`

export default SAVE_LANGUAGE_MUTATION
